html {
  height: 100%;
  /* width: 100vw; */
}


body {
  height: 100%;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root>div {
  height: 100%;
}

.circles_light {
  height: 100%;
  margin: 0;
  padding: 0;
  background-image: url(./assets/circles/GalbenDr.svg), url(./assets/circles/GriSt.svg), url(./assets/circles/GriDr.svg), url(./assets/circles/GalbenSt.svg);
  background-repeat: no-repeat;
  background-position:
    top 0px right -150px,
    top 600px left -150px,
    bottom 500px right -150px,
    bottom -75px left -150px;
  background-clip: content-box;
}

.circles_dark {
  height: 100%;
  margin: 0;
  padding: 0;
  background-image: url(./assets/circles/DGalbenDr.svg), url(./assets/circles/DGriSt.svg), url(./assets/circles/DGriDr.svg), url(./assets/circles/DGalbenSt.svg);
  background-repeat: no-repeat;
  background-position:
    top 0px right -150px,
    top 600px left -150px,
    bottom 500px right -150px,
    bottom -75px left -150px;
  background-clip: content-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

::-webkit-scrollbar {
  width: 7px;
}

::-webkit-scrollbar-track {
  background: #FFFFFF;
}

::-webkit-scrollbar-thumb {
  background: #C4C4C4;
  border-radius: 25px;
}

.container {
  overflow-x: auto;
  margin-right: auto;
  margin-left: auto;
  box-shadow: 0px 4px 4px 0px #00000026;
  height: 1530px;
  /* margin: 25px; */
  /* margin-top: 60px; */
  /* padding-right: 35px; */
  /* padding-left: 35px; */
}

.newsImage {
  width: 200px;
  height: 125px;
  object-fit: cover;
  flex-shrink: 0;
}

/* .parentHead {} */

/* .childHead {} */

.parentHead:hover .childHead {
  font-weight: 600;
}

.progressNumber {
  visibility: hidden;
}

.progressBar:hover .progressNumber {
  visibility: visible;
}