.footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 15px;
    padding-bottom: 15px;
    /* padding-left: 5.5rem;
    padding-right: 5.5rem; */
    background-color: #000;
}

.nav {
    display: flex;
    align-items: center;
}

.item {
    font-size: 15px;
    line-height: 16px;
    font-weight: 600;
    text-decoration: none;
    margin-left: auto;
    color: #fff;
}

.item:hover {
    color: #aaa;
}

/* .item:last-child {
    margin-right: 0;
} */

.contact {
    user-select: all;
}